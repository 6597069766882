<template>
    <div class="login">
        <div class="login_content">
            <div class="main">
                <div class="login_main">
                    <div class="login_box">
                        <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
                            <h3 class="login_title"><span>登录</span></h3>
                            <el-form-item prop="phone">
                                <el-input v-model="loginForm.phone" type="phone" maxlength="11" auto-complete="off" placeholder="请输入手机号">
                                    <!-- <el-image slot="prefix" :src="'https://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/login/icon__user.png'" style="width: 24px;height: 24px;margin-top: 8px;"></el-image> -->
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <el-input v-model="loginForm.code" type="text" auto-complete="off" placeholder="请输入验证码">
                                    <!-- <el-image slot="prefix" :src="'https://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/login/icon__password.png'" style="width: 24px;height: 24px;margin-top: 8px;"></el-image> -->
                                    <div class="code_message" slot="suffix">
                                        <el-button type="text" size="mini" maxlength="6" @click="handleLoginCode" :disabled="banGetCode">{{ codeTxt || '获取验证码' }}</el-button>
                                    </div>
                                </el-input>
                            </el-form-item>
                            <h6 class="login_desc"><span>首次登录即注册</span></h6>
                            <div class="login_btn" :class="loading ? 'login_btn_loading' : ''" @click.prevent="handleLogin" @keydown="handleLogin">
                                <span v-if="loading">登录中...</span>
                                <span v-else>登录</span>
                            </div>
                            <el-form-item  style="margin-top: 33px;">
                                <el-checkbox v-model="agreementChecked" class="checkbox_desc">
                                    <span>已阅读并同意</span>
                                    <el-link type="primary" :underline="false">《用户协议》</el-link>
                                    <span>和</span>
                                    <el-link type="primary" :underline="false">《隐私政策》</el-link>
                                </el-checkbox>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="bottom_main hidden-lg-and-down">
                    <div class="bottom_links_list">
                        <div class="bottom_link_item" v-for="(link, index) in links" :key="index">
                            <el-link :href="link.href">{{ link.name }}</el-link>
                        </div>
                    </div>
                    <div style="margin-top: 8px;">
                        <span>© 2023 重庆魔女科技股份有限公司 版权所有 渝ICP备2021002423号-2 重庆市渝北区中渝爱都会国际广场3号楼605室</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userLogin, loginCode  } from '@/api/user'
import { setToken } from '@/utils/auth'

export default {
    data() {
        var checkPhone = (rule, value, callback) => { // 手机号验证
            if (!value) {
                return callback(new Error('手机号不能为空'))
            } else {
                const reg = /^1[3456789]\d{9}$/
                if (reg.test(value)) {
                    callback()
                } else {
                    return callback(new Error('请输入正确的手机号'))
                }
            }
        }
        return {
            loginForm: {
                phone: '',
                code: ''
            },
            agreementChecked: false,
            codeTxt: '获取验证码',
            banGetCode: false,
            loginRules: {
                phone: [
                    { required: true, trigger: 'blur', message: '请输入手机号' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                code: [
                    { required: true, trigger: 'blur', message: '请输入验证码' },
                    { min: 4, max: 6, trigger: 'blur', message: '请输入正确的验证码' }
                ],
            },
            loading: false,
            links: [
                {name: '魔女科技', href: '/'},
                {name: 'ASO优化', href: '/aso'},
                {name: 'ASA服务', href: '/asa'},
                {name: 'CPD服务', href: '/cpd'},
                {name: '小程序ASO', href: '/mpAso'},
                {name: '行业干货', href: '/news'},
                {name: '关于我们', href: '/home/about'},
            ]
        }
    },
    mounted() {
        
    },
    methods: {
        // 获取验证码
        handleLoginCode() {
            this.$refs.loginForm.validateField('phone', (valid) => {
                if (valid === '') {
                    if (!this.agreementChecked) {
                        this.$message.error('请阅读并同意《用户协议》和《隐私协议》')
                        return
                    }
                    const phone = this.loginForm.phone
                    loginCode({phone})
                    .then(response => {
                        this.$message.success('验证码已发送')
                        let seconds = 60
                        this.banGetCode = true
                        let timer = setInterval(() => {
                            this.codeTxt = seconds + '秒后再次获取'
                            if (seconds <= 0) {
                                this.banGetCode = false
                                clearInterval(timer)
                            }
                            seconds -- 
                        }, 1000)
                    })
                } 
            })
        },

        // 登录
        handleLogin() {
            if (!this.agreementChecked) {
                this.$message.error('请阅读并同意《用户协议》和《隐私协议》')
                return
            }
            if (this.loading) {
                return
            }
            this.$refs.loginForm.validate(result => {
                if (result) {
                    this.loading = true
                    userLogin(this.loginForm)
                    .then(response => {
                        this.$message.success(response.msg)
                        this.loading = false
                        setToken(response.data.token)
                        const _prev_ = this.getStorage('_prev_')
                        if (_prev_) {
                            this.removeStorage('_prev_')
                            this.$router.push(_prev_)
                        } else {
                            this.$router.push('/')
                        }
                    })
                }
            })
            
        }
    }
}
</script>

<style scoped lang="scss">
.login {
    height: 100%;
    
}

.login {
    background-image: url('https://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/news/login__background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
    .login_box {
        width: 100%;
        max-width: 460px;
        height: 480px;
        background-image: url('https://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/login/login__box__bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        padding: 50px 40px 40px 40px;
    }

    .login_title {
        margin-bottom: 40px;
        text-align: center;
        span {
            font-size: 38px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 38px;
        }
    }

    .login_desc {
        margin-bottom: 43px;
        text-align: center;
        span {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
        }
    }

    .login_btn {
        background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
        box-shadow: 0px 5px 10px 0px rgba(126,85,221,0.4), inset 0px 0px 18px 0px rgba(255,255,255,0.61);
        border-radius: 30px;
        padding: 18px 20px;
        text-align: center;
        cursor: pointer;
        opacity: 1;
        transition: all 0.3s;
        span {
            font-size: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
        }
    }

    .login_btn.login_btn_loading {
        opacity: 0.5;
    }

    .checkbox_desc {
        span {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
        }
    }
    .bottom_main {
        position: fixed;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;
        .bottom_links_list {
            display: flex;
            align-items: center;
            justify-content: center;
            .bottom_link_item {
                margin-right: 40px;
                
            }
        }
        span {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #656494;
            line-height: 14px;
        }
    }
.main {
    margin: 0 auto;
}

@media screen and (min-width: 1200px) {
    .login {
        background-image: url('https://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/login/login__page__bg.png');
    }
    .login_content {
        height: 100%;
    }
    .main {
        width: 1200px;
        height: 100%;
        position: relative;
    }
    .login_main {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }
    
    
}

@media screen and (max-width: 1199px) {
    .login {
        position: relative;
    }

    .login_box {
        position: absolute;
        bottom: 0;
        background: transparent;
    }

    .login_title {
        margin-bottom: 21px;
        span {
            font-size: 23px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #6D45DB;
            line-height: 23px;
        }
    }

}
</style>

<style>
    .el-input--prefix .el-input__inner {
        padding-left: 36px;
    }

    .el-form-item {
        margin-bottom: 24px;
    }
</style>
<template>
    <div class="home column">
        <div class="head">
            <nav-top></nav-top> 
        </div>
        <div class="main column flex-1">
            <router-view :screenType="screenType" :clientWidth="clientWidth"></router-view>
        </div>
        <div class="foot">
            <foot-bottom></foot-bottom>
        </div>
    </div>
</template>
  
<script>
import NavTop from "@/Layout/NavTop.vue"
import FootBottom from "@/Layout/FootBottom.vue"

export default {
    data() {
        return {
            clientWidth: 0,
            screenType: '', //largePc、pc、smallPc、paid、phone
        }
    },
    components:{
        NavTop, 
        FootBottom
    },
    mounted() {
        this.clientWidth = document.body.clientWidth
        this.handleScreenType()
        window.addEventListener('resize', () => {
            this.clientWidth = document.body.clientWidth
            this.handleScreenType()
        }) 
    },
    destoryed() {
        window.removeEventListener('resize', () => {
            console.log('组件销毁')
        })
    },
    methods: {
        handleScreenType() {
            const screen = this.clientWidth
            if (screen <= 767) {
                this.screenType = 'phone'
            } else if (screen >= 768 && screen <= 1023) {
                this.screenType = 'paid'
            } else if (screen >= 1024 && screen <= 1599) {
                this.screenType = 'smallPc'
            } else if (screen >= 1600 && screen <= 1919) {
                this.screenType = 'pc'
            } else if (screen >= 1920) {
                this.screenType = 'largePc'
            }
            return this.screenType
        },
    }
};
</script>
  
<style lang="scss" scoped>
@import url(@/assets/css/common.scss);
.home{
    width: 100%;
    min-height: 100%;
    .head{
        width: 100%;
        height: 100%;
    }
    .main{
        width: 100%;
        height: 100%;
    }
    .foot{
        height: 100%;
    }
}
</style>

<style>
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

#icon_menu_module {
    background-image: url('https://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/home/4c76fadc7af6443d06306aae3234304fb22ce173a.png') !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    width: 117px !important;
    height: 127px !important;
}

#icon_menu_module p {
    display: none !important;
}

#icon_menu_module .border_line {
    display: none !important;
}

.submit__btn {
    background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
    box-shadow: 0px 5px 10px 0px rgba(126,85,221,0.4), inset 0px 0px 18px 0px rgba(255,255,255,0.61);
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
}

.submit__btn:hover, .submit__btn:active, .submit__btn:focus {
    opacity: 0.7;
}

.submit__btn span {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
}

.plain__btn {
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    border-radius: 18px;
    border: 1px solid #433FFF;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
}

.plain__btn span {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #433FFF;
    line-height: 14px;
}

.plain__btn:hover, .plain__btn:active, .plain__btn:focus {
    background: #433FFF;
}

.plain__btn:hover span, .plain__btn:active span, .plain__btn:focus span {
    color: #FFFFFF;
}

.flex__row {
    display: flex;
}

.align__center {
    align-items: center;
}

.justify__around {
    justify-content: space-around;
}

.justify__between {
    justify-content: space-between;
}


</style>
<template>
    <div class="home">
        <div class="top__banner">
            <div class="hidden__1199__down show__1200__up large__banner">
                <el-carousel trigger="click" arrow="never" height="600px">
                    <el-carousel-item v-for="(item, index) in banners" :key="index" autoplay="false">
                        <img :src="item.img_src" class="carousel-item__image">
                    </el-carousel-item>
                </el-carousel>
                <div class="large__banner__content">
                    <div class="container">
                        <div class="flex__row align__center">
                            <div class="banner__content">
                                <h1 class="large__title animate__animated animate__bounce">
                                    ASO全案营销专家
                                </h1>
                                <h5 class="small__desc animate__animated animate__bounce">
                                    助力APP高性价比获取全球优质客户
                                </h5>
                                <div class="content__button color__button" v-if="!isShow">
                                    免费注册
                                </div>
                            </div>
                            <div class="login_box" v-if="!isShow">
                                <div class="login_title">
                                    <span>免注册登录</span>
                                </div>
                                <el-form :model="loginForm" ref="loginForm" :rules="loginRules">
                                    <el-form-item prop="phone">
                                        <el-input placeholder="请输入手机号码" v-model="loginForm.phone" maxlength="11">
                                            <div class="country_code" slot="prefix"><span>+86</span></div>
                                        </el-input> 
                                    </el-form-item>
                                    <el-form-item prop="code">
                                        <el-input placeholder="请输入验证码" v-model="loginForm.code" maxlength="6">
                                            <div class="code_message" slot="suffix" @click="handleLoginCode">
                                                <span>{{ codeTxt }}</span>
                                            </div>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <div class="submit__btn" @click="handleLogin">
                                            <span v-if="loading">登录中...</span>
                                            <span v-else>登录</span>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-checkbox v-model="agreementChecked" class="user_agreement">
                                            <span>已阅读并同意</span>
                                            <el-link type="primary" :underline="false">《用户协议》</el-link>
                                            <span>和</span>
                                            <el-link type="primary" :underline="false">《隐私政策》</el-link>
                                        </el-checkbox>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up small__banner">
                <el-image :src="h5Banner.img_src" style="width:100%;height:auto;"></el-image>
                <div class="consult__button">
                    <div class="white__button" @click="consultNow()">
                        立即咨询
                    </div>
                </div>
            </div>
        </div>

        <div class="our__advantage">
            <div class="hidden__1199__down show__1200__up our__advantage__large">
                <div class="container">
                    <div class="row__content_ad" style="padding-top: 60px;">
                        <h1 class="content__title">我们的优势</h1>
                    </div>
                    <div class="content__main">
                        <!-- <leftToRightUnfold :list="advantages" :width="220" :height="466" :hoverWidth="402"></leftToRightUnfold>  -->
                        <div class="flex__row collapse__box">
                            <div class="collapse__item animate__animated animate__backInLeft" v-for="(item, index) in advantages" :key="index">
                                <div class="collapse__item__box">
                                    <div class="collapse__item__cover" style="width: 220px;height: 466px;padding: 66px 19px 30px 0;"
                                        :style="{backgroundImage: 'url('+item.select_background_img+')'}">
                                        <div class="collapse__item__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="collapse__item__sub__title">
                                            <span>{{ item.short_content || '' }}</span>
                                        </div>
                                    </div>
                                    <div class="collapse__item__open" style="width: 402px;height: 468px;"
                                    :style="{backgroundImage: 'url('+item.selected_background_img+')'}">
                                        <div class="collapse__item__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="collapse__item__sub__title">
                                            <span>{{ item.short_content || '' }}</span>
                                        </div>
                                        <div class="collapse__item__desc">
                                            <span>{{ item.content || '' }}</span>
                                        </div>
                                        <div class="collapse__item__buttons">
                                            <div class="color__button" @click="consultNow">立即咨询</div>
                                            <div class="blue-white__button" @click="consultNow">查看更多</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up our__advantage__small">
                <div class="row__content" style="padding: 10px 0 0 0;">
                    <h1 class="content__title">我们的优势</h1>
                </div>
                <div class="advantaget__main">
                    <div class="advantage__item" v-for="(item, index) in advantages" :key="index" :class="index === advantages.length - 1 ? 'advantage__item__row' : ''">
                        <div class="advantage__box" :style="{backgroundImage: 'url('+item.h5_background_img+')'}">
                            <div class="advantage__title">
                                <span>{{ item.title || '' }}</span>
                            </div>
                            <div class="advantage__sub__title">
                                <span>{{ item.title || '' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="business__introduce">
            <div class="hidden__1199__down show__1200__up business__introduce__large">
                <div class="container">
                    <h1 class="content__title" style="color: #333333">
                        业务介绍
                    </h1>
                    <div class="business__introduce__large__content flex__row">
                        <div class="business__large__item" v-for="(item, index) in businesses" :key="index">
                            <div class="business__large__box">
                                <div class="flex__row">
                                    <div class="business__large__logo">
                                        <el-image :src="item.cover" class="icon__logo"></el-image>
                                    </div>
                                    <div class="business__large__option">
                                        <div class="business__large__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="business__large__desc">
                                            <span>{{ item.content || '' }}</span>
                                        </div>
                                        <div class="business__large__view__button" @click="consultNow">
                                            <span>了解详情</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up business__introduce__small" style="padding: 10px 0;">
                <h1 class="content__title" style="color: #333333">
                    业务介绍
                </h1>
                <el-carousel trigger="click" arrow="never" height="300px" ref="nop" :autoplay="type">
                    <div @touchstart="startTouch($event)" @touchmove="moveTouch($event)">
                        <el-carousel-item v-for="(item, index) in businesses" :key="index">
                            <div class="business__small__box">
                                    <div class="flex__row">
                                        <div class="business__small__logo">
                                            <el-image :src="item.cover" class="icon__logo"></el-image>
                                        </div>
                                        <div class="business__small__option">
                                            <div class="business__small__title">
                                                <span>{{ item.title || '' }}</span>
                                            </div>
                                            <div class="business__small__desc">
                                                <span>{{ item.content || '' }}</span>
                                            </div>
                                            <div class="business__small__view__button" @click="consultNow">
                                                <span>了解详情</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </el-carousel-item>
                    </div>
                </el-carousel>
            </div>
        </div>

        <div class="excellent__cases">
            <div class="hidden__1199__down show__1200__up large__cases">
                <div class="container">
                    <h1 class="content__title" style="color: #333333;">
                        优秀案例
                    </h1>
                    <div class="case_type_nav">
                        <div 
                            class="case_type_nav_item" 
                            v-for="(item, index) in caseTypes"
                            :class="item.id === actCaseType.id ? 'active' : ''" 
                            :key="index"
                            @click="handleCaseTypeChange(item)">
                            <div class="case_type_name">
                                <span>{{ item.name || '' }}</span>
                            </div>
                            <div v-if="item.id === actCaseType.id" class="case_type_learn_detail">
                                <span>了解详情</span>
                            </div>
                            <div class="case_type_icon">
                                <el-image v-if="item.id != actCaseType.id" :src="item.cover" style="width: 54px;height: 54px;"></el-image>
                                <el-image v-else :src="item.selected_cover" style="width: 54px;height: 54px;"></el-image>
                            </div>
                        </div>
                    </div>
                    <div class="case_box" v-if="cases.length > 0">
                        <div class="case_lists">
                            <div class="case_nav">
                                <div class="case_item_nav" :class="item.id === actCase.id ? 'active' : ''" v-for="(item, index) in cases" :key="index" @click="handleCaseChange(item)">
                                    <div class="checked_ico"></div>
                                    <div class="ico">
                                        <el-image :src="item.cover"></el-image>
                                    </div>
                                </div>
                            </div>
                            <div class="case_detail">
                                <div class="case_detail_title">
                                    <span>{{ actCase.title || '' }}</span>
                                </div>
                                <div class="case_detail_main">
                                    <div v-html="actCase.content"></div>
                                </div>
                            </div>
                        </div>
                        <div class="case_image">
                            <el-image :src="actCase.selected_background_img" style="width: 437px;height: 707px;"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up small__cases">
                <h1 class="content__title" style="color: #333333">优秀案例</h1>
                <div class="mobile__cases__nav">
                    <div class="mobile__cases__type__nav__slider">
                        <div 
                            class="mobile__cases__type__nav__item" 
                            :class="item.id === actCaseType.id ? 'active' : ''" 
                            v-for="(item, index) in caseTypes" :key="index">
                            <div class="mobile__cases__type__nav__box" @click="handleCaseTypeChange(item)">
                                <div class="mobile__cases__type__icon">
                                    <el-image v-if="item.id === actCaseType.id" class="icon" :src="item.selected_cover"></el-image>
                                    <el-image v-else class="icon" :src="item.cover"></el-image>
                                </div>
                                <div class="mobile__cases__type__name">
                                    <span>{{ item.name || '' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile_case_box" v-if="cases.length > 0">
                        <div class="mobile_case_lists">
                            <div class="mobile_case_nav">
                                <div class="mobile_case_item_nav" :class="item.id === actCase.id ? 'active' : ''" v-for="(item, index) in cases" :key="index" @click="handleCaseChange(item)">
                                    <div class="checked_ico"></div>
                                    <div class="ico">
                                        <el-image :src="item.cover"></el-image>
                                    </div>
                                </div>
                            </div>
                            <div class="mobile_case_detail">
                                <div class="mobile_case_detail_title">
                                    <span>{{ actCase.title || '' }}</span>
                                </div>
                                <div class="mobile_case_detail_main">
                                    <div v-html="actCase.content"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="mobile_case_image">
                            <el-image :src="actCase.selected_background_img" style="width: 437px;height: 707px;"></el-image>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="industry__infor">
            <div class="hidden__1199__down show__1200__up large__infor">
                <div class="container">
                    <h1 class="content__title" style="color: #333333;">行业资讯</h1>
                </div>
                <div class="news_box">
                    <div class="container">
                        <el-row>
                            <el-col :span="12" v-for="(item, index) in newses" :key="index">
                                <el-link :underline="false" :href="'/news/detail?id='+item.id" style="display: block">
                                    <div :class="index % 2 === 0 ? 'news_box_left' : 'news_box_right'">
                                        <div class="news_item">
                                            <div class="news_icon">
                                                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/icon_news.png'" style="width: 18px;height: 18px;"></el-image>
                                            </div>
                                            <div class="news_content">
                                                <span>{{ item.title || '' }}</span>
                                                <el-image v-if="item.is_hot" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/hot_icon.png'" style="width: 16px;height: 18px;position: absolute;right: 10px;"></el-image>
                                            </div>
                                            <div class="news_date" v-if="item.update_time">
                                                <p>{{ handleTimer(item.update_time, 'day') }}</p>
                                                <span>{{ handleTimer(item.update_time, 'year') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-link>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up small__infor">
                <h1 class="content__title" style="color: #333333">行业资讯</h1>
                <div class="news_box">
                    <el-row>
                        <el-col :span="24" v-for="(item, index) in newses" :key="index">
                            <div class="news_box_right">
                                <div class="news_item">
                                    <div class="news_icon">
                                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/icon_news.png'" style="width: 18px;height: 18px;"></el-image>
                                    </div>
                                    <div class="news_content">
                                        <span>{{ item.title || '' }}</span>
                                        <!-- <el-image v-if="item.is_hot" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/hot_icon.png'" style="width: 16px;height: 18px;position: absolute;right: 10px;"></el-image> -->
                                    </div>
                                    <div class="news_date" v-if="item.update_time">
                                        <p>{{ handleTimer(item.update_time, 'day') }}</p>
                                        <span>{{ handleTimer(item.update_time, 'year') }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getList, getCategory, getBanner } from '@/api/index'
import { getToken } from '@/utils/auth'
import { userLogin, loginCode  } from '@/api/user'
import { setToken } from '@/utils/auth'
import { consult } from '@/assets/consult'
import leftToRightUnfold from '@/components/leftToRightUnfold/index.vue'

export default {
    props: ['screenType'],
    components: {leftToRightUnfold},
    data() {
        var checkPhone = (rule, value, callback) => { // 手机号验证
                if (!value) {
                    return callback(new Error('手机号不能为空'))
                } else {
                    const reg = /^1[3456789]\d{9}$/
                    if (reg.test(value)) {
                        callback()
                    } else {
                        return callback(new Error('请输入正确的手机号'))
                    }
                }
        }
        return {
            banners: [],
            h5Banner: {},
            loginForm: {
                phone: null,
                code: null
            },
            loginRules: {
                phone: [
                    { required: true, trigger: 'blur', message: '请输入手机号' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                code: [
                    { required: true, trigger: 'blur', message: '请输入验证码' },
                    { min: 4, max: 6, trigger: 'blur', message: '请输入正确的验证码' }
                ],
            },
            agreementChecked: false,
            codeTxt: '获取验证码',
            banGetCode: false,
            loading: false,
            isRead: false,
            advantages: [],
            businesses: [],
            caseTypes: [],
            actCaseType: {},
            cases: [],
            actCase: {},
            newses: [],
            isShow: false,
            type: false,
            startX: '',
            startY: '',
            moveY: '',
            moveX: '',
            startTime: ''
        }
    },
    created() {
        this.getAdvantages()
        this.getBusiness()
        this.getCaseTypes()
        this.getNews()
        this.getBanners()
        this.getH5Banners()
        this.isShow = getToken() ? true : false
    },
    mounted() {

    },
    methods: {
        startTouch(e) {
            // console.log('start:', e)
            this.startX = e.touches[0].clientX;
            this.startY = e.touches[0].clientY;
        },

        moveTouch(e) {
            // console.log('end:', e)
            this.moveX = e.touches[0].clientX;
            this.moveY = e.touches[0].clientY;
            var nowtime = new Date().getTime();
            if (this.startTime == undefined || nowtime > this.startTime) {
                if (this.startX - this.moveX <= 0) { // 右滑触发
                this.prev();
                return false;
                } else {
                this.next();
                return false;
                }
            }
        },

        prev() {
            this.$refs.nop.prev();
            this.startTime = new Date().getTime() + 500;
        },
        
        next() {
        this.$refs.nop.next();
        this.startTime = new Date().getTime() + 500;
        },

        consultNow() {
            consult()
        },

        // 获取验证码
        handleLoginCode() {
            if (this.banGetCode) {
                return
            }
            this.$refs.loginForm.validateField('phone', (valid) => {
                if (valid === '') {
                    if (!this.agreementChecked) {
                        this.$message.error('请阅读并同意《用户协议》和《隐私协议》')
                        return
                    }
                    const phone = this.loginForm.phone
                    loginCode({phone})
                    .then(response => {
                        this.$message.success('验证码已发送')
                        let seconds = 60
                        this.banGetCode = true
                        let timer = setInterval(() => {
                            this.codeTxt = seconds + '秒后再次获取'
                            if (seconds <= 0) {
                                this.banGetCode = false
                                clearInterval(timer)
                            }
                            seconds -- 
                        }, 1000)
                    })
                } 
            })
        },

        // 登录
        handleLogin() {
            if (!this.agreementChecked) {
                this.$message.error('请阅读并同意《用户协议》和《隐私协议》')
                return
            }
            if (this.loading) {
                return
            }
            this.$refs.loginForm.validate(result => {
                if (result) {
                    this.loading = true
                    userLogin(this.loginForm)
                    .then(response => {
                        this.$message.success(response.msg)
                        this.loading = false
                        setToken(response.data.token)
                        location.reload()
                    })
                }
            })
            
        },

        // 请求banner
        getBanners() {
            getBanner({code: 'home'})
            .then(response => {
                this.banners = response.data
            })
        },

        // 请求h5banner
        getH5Banners() {
            getBanner({code: 'h5-home'})
            .then(response => {
                this.h5Banner = response.data[0]
            })
        },

        // 请求我们的优势
        getAdvantages() {
            getList({
                category_id: 2,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.advantages = response.data.data
            })
        },

        // 请求业务介绍
        getBusiness() {
            getList({
                category_id: 3,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.businesses = response.data.data
            })
        },

        // 请求优秀案例分类
        getCaseTypes() {
            getCategory({
                category_id: 26,
                page: 1,
                list_rows: 10000
            })
            .then(response => {
                this.caseTypes = response.data
                this.handleCaseTypeChange(this.caseTypes[0])
            })
        },

        // 请求案例列表
        getCaseList(categoryId) {
            getList({
                category_id: categoryId,
                page: 1,
                list_rows: 10000
            })
            .then(response => {
                this.handleCaseChange(response.data.data[0])
                this.cases = response.data.data
            })
        },

        // 切换caseType
        handleCaseTypeChange(item) {
            this.actCaseType = item
            // 请求案例列表
            this.getCaseList(item.id)
        },

        // 切换case
        handleCaseChange(item) {
            this.actCase = item
        },

        // 请求行业资讯
        getNews() {
            getList({
                category_id: 19,
                page: 1,
                list_rows: 10,
                is_recommend: 1
            })
            .then(response => {
                this.newses = response.data.data
            })
        },

        // 输入时间得到年月日
        handleTimer(date, type) {
            const date_arr = date.split('-')
            if (type === 'year') {
                return date_arr[0]
            } else (type === 'day')
                return date_arr[1] + '/' + date_arr[2].split(' ')[0]
        },

        // toNewsDetail(id) {
        //     this.$router.push({path:'/news/detail',query: {id:id}})
        // },
    },
}
</script>

<style lang="scss" scoped>
.large__banner {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/home_banner_1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .large__banner__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10001;
        width: 100%;
        height: 100%;
        .container {
            height: 100%;
        }

        .flex__row {
            height: 100%;
            justify-content: space-between;
        }
    }
}

.carousel-item__image {
    width: 100vw;
    height: 100%;
}

.login_box {
    width: 380px;
    height: 346px;
    background: #FFFFFF;
    border-radius: 30px;
    opacity: 0.85;
    margin: 42px 0;
    padding: 23px 22px;

    .country_code {
        padding: 0 5px;
        span {
            font-size: 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }

    .code_message {
        cursor: pointer;
        padding-right: 12px;
        span {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }

    .user_agreement {
        span {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
        }
    }
}

.login_title {
    text-align: center;
    margin-bottom: 26px;

    span {
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
    }
}

.small__banner {
    margin-bottom: -6px;
    position: relative;
    .consult__button {
        position: absolute;
        bottom: 210px;
        left: 40px;
        z-index: 10001;
    }
}

.our__advantage {
    .our__advantage__large {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/bg__advantage__row.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

       .collapse__item__box {
            width: 220px;
            height: 468px;
       }

       .collapse__item__box:hover {
            width: 402px;

            .collapse__item__cover {
                left: -220px;
            }

            .collapse__item__open {
                opacity: 1;
            }
       }
    }

    .our__advantage__small {
        background-image: url('http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/background__advantage.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .advantaget__main {
            display: flex;
            flex-wrap: wrap;
        }

        .advantage__item {
            width: 50%;
        }

        .advantage__item.advantage__item__row {
            width: 100%;
            .advantage__box {
                height: 120px;
            }
            .advantage__title {
                width: 240px;
            }

            .advantage__sub__title {
                width: 240px;
            }
        }

        .advantage__box {
            background-image: url(http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/background__advantage__item__1.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: 189px;
        }

        .advantage__title {
            padding-bottom: 10px;
            text-align: center;
            padding: 15px 5px 5px 5px;
            span {
                font-size: 15px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 30px;
            }
        }

        .advantage__title::after {
            content: '';
            display: block;
            margin: 0 auto;
            width: 32px;
            height: 4px;
            background: #333333;
            border-radius: 2px;
        }

        .advantage__sub__title {
            padding-top: 5px;
            padding-left: 18px;
            padding-right: 17px;
            text-align: center;
            span {
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #424242;
                line-height: 30px;
            }
        }
    }
}

.business__introduce {
    .business__introduce__large {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/home_banner_3.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 60px;
        padding-bottom: 270px;

        .business__introduce__large__content {
            display: flex;
            flex-wrap: wrap;
        }

        .business__large__item {
            width: 50%;
            cursor: pointer;
        }

        .business__large__box {
            background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/business_bg.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 12px 23px 34px 23px;
        }

        .business__large__logo {
            padding: 37px 20px 100px 22px;
            .icon__logo {
                width: 120px;
                height: 120px;
            }
        }

        .business__large__option {
            padding: 37px 28px 40px 0;
        }

        .business__large__title {
            padding-bottom: 16px;
            span {
                font-size: 30px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 30px;
            }
        }

        .business__large__desc {
            padding-bottom: 32px;
            span {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
            }
        }

        .business__large__view__button {
            border-radius: 18px;
            border: 1px solid #433FFF;
            display: inline-block;
            padding: 5px 42px;
            transition: all 0.2s;
            span {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #433FFF;
                line-height: 14px;
            }
        }

        .business__large__item:hover, .business__large__item:focus .business__large__item:active {
            .business__large__box {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/business_bg_hover.png');
            }
            

            .business__large__view__button {
                border: none;
                background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
                box-shadow: 0px 5px 10px 0px rgb(126 85 221 / 40%), inset 0px 0px 18px 0px rgb(255 255 255 / 61%);
                span {
                    color: #FFFFFF;
                }
            }
        }
    }

    .business__introduce__small {
        background-image: url('http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/background__introduce.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .business__small__box {
            background-image: url('http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/business__item__background.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 3px 10px 15px 10px;
        }

        .business__small__logo {
            padding: 20px 11px 80px 11px;
            .icon__logo {
                width: 70px;
                height: 70px;
            }
        }

        .business__small__option {
            padding: 20px 9px 24px 0;
        }

        .business__small__title {
            padding-bottom: 10px;
            span {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 16px;
            }
        }

        .business__small__desc {
            padding-bottom: 14px;
            span {
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 16px;
            }
        }

        .business__small__view__button {
            border-radius: 20px;
            display: inline-block;
            padding: 6px 23px;
            background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
            box-shadow: 0px 5px 10px 0px rgb(126 85 221 / 40%), inset 0px 0px 18px 0px rgb(255 255 255 / 61%);
            span {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 16px;
            }
        }
    }
}

.excellent__cases {
    .large__cases {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/home_banner_4.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 60px 20px;

        .case_type_nav {
            display: flex;
            align-items: center;
            .case_type_nav_item {
                width: 160px;
                height: 160px;
                padding-top: 30px;
                background: linear-gradient(180deg, #EDEDED 0%, #FFFFFF 100%);
                border-right: 1px solid #FFFFFF;
                cursor: pointer;
                text-align: center;
                align-items: center;
                transition: all 0.2s;
                .case_type_name {
                    margin-bottom: 15px;
                    span {
                        font-size: 20px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        line-height: 20px;
                    }
                }

            }

            .case_type_nav_item.active {
                box-shadow: 0px 5px 10px 0px rgb(126 85 221 / 40%), inset 0px 0px 18px 0px rgb(255 255 255 / 61%);
                background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
                width: 254px;
                text-align: left;
                padding: 20px 10px 10px 40px;

                .case_type_name {
                    margin-bottom: 10px;
                    span {
                        color: #FFFFFF;
                    }
                }

                .case_type_learn_detail {
                    span {
                        display: inline-block;
                        width: 110px;
                        height: 36px;
                        text-align: center;
                        border-radius: 18px;
                        border: 1px solid #FFFFFF;
                        font-size: 14px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 36px;
                    }
                }
                .case_type_icon {
                    text-align: right;
                }
            }

            .case_type_nav_item:first-child {
                border-radius: 20px 0 0 20px;
            }

            .case_type_nav_item:last-child {
                border-radius: 0 20px 20px 0;
                border-right: none;
            }
        }

        .case_box {
            display: flex;
            justify-content: space-between;
            margin-top: 61px;

            .case_lists {
                margin-right: 77px;

               .case_nav {
                    display: flex;
                    align-items: center;
                    margin-bottom: 64px;
                    flex-wrap: wrap;
               }

               .case_item_nav {
                    margin-right: 50px;
                    text-align: center;
                    cursor: pointer;
                    

                    .checked_ico {
                        width: 32px;
                        height: 4px;
                        background: transparent;
                        border-radius: 2px;
                        margin: 0 auto 24px auto;
                        transition: all 0.2s;
                    }

                    .ico {
                        width: 64px;
                        height: 64px;
                        border-radius: 10px;
                        transition: all 0.2s;
                    }
               }

               .case_item_nav.active {
                    .checked_ico {
                        background: #433FFF;
                    }

                    .ico {
                        width: 96px;
                        height: 96px;
                    }
               }

               .case_detail_title {
                    padding-bottom: 20px;
                    span {
                        font-size: 28px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        line-height: 28px;
                    }
                }

            }
        }
    }

    .small__cases {
        background-image: url('http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/background__cases.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 15px 0;

        .mobile__cases__type__nav__slider {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            overflow-y: scroll;
        }        

        .mobile__cases__type__nav__item {
            background: url('http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/case__type__background.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 92px;
            height: 92px;
            text-align: center;
            padding-top: 16px;
            transition: all 0.2s;

            .mobile__cases__type__icon {
                height: 24px;
                .icon {
                    width: 24px;
                    height: 24px;
                }
            }

            .mobile__cases__type__name {
                span {
                    font-size: 13px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 16px;
                }
            }
        }

        .mobile__cases__type__nav__item.active {
            background-image: url('http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/case__type__background__active.png');

            .mobile__cases__type__name {
                span {
                    color: #FFFFFF;
                }
            }
        }

        .mobile_case_box {
            padding: 0 20px;
        }

        .mobile_case_lists {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 20px 10px;

            .mobile_case_nav {
                display: flex;
                align-items: center;
                margin-bottom: 32px;
            }
               
            .mobile_case_item_nav {
                margin-right: 50px;
                text-align: center;
                cursor: pointer;
                    
                .checked_ico {
                    width: 32px;
                    height: 4px;
                    background: transparent;
                    border-radius: 2px;
                    margin: 0 auto 15px auto;
                    transition: all 0.2s;
                }

                .ico {
                    width: 35px;
                    height: 35px;
                    border: 1px solid #A712B4;
                    border-radius: 10px;
                    transition: all 0.2s;
                }
            }

            .mobile_case_item_nav.active {
                .checked_ico {
                    background: #433FFF;
                }

                .ico {
                    width: 50px;
                    height: 50px;
                }
            }

            .mobile_case_detail_title {
                padding-bottom: 10px;
                span {
                    font-size: 14px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 14px;
                }
            }

        }
    }
}

.industry__infor {
    .large__infor {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/home_banner_5.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 60px 20px;

        .news_box_left {
            .news_item {
                padding: 21px 38px 21px 0;
                border-bottom: 1px solid #D8D8D8;
            }
        }
    }

    .small__infor {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/mobile__infor__background.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 15px 10px;
        .news_box_right {
            padding-left: 0;
        }
    }

    .news_box_right {
        padding-left: 24px;
        .news_item {
            background: #FFFFFF;
            box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
            border-radius: 6px;
        }
    }

    .news_item {
        display: flex;
        align-items: center;
        padding: 22px 27px;
        cursor: pointer;
        margin-bottom: 20px;
        .news_icon {
            height: 18px;
        }

        .news_content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 74px;
            padding-left: 6px;
            border-right: 1px solid rgba(151, 151, 151, 0.13); 
            position: relative;
            width: 100%;

            span {
                overflow: hidden;
                font-size: 13px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #2A3A5A;
                line-height: 16px;
            }
        }

        .news_date {
            text-align: center;
            padding-left: 28px;

            p {
                margin: 0;
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #C4C4C4;
                line-height: 18px;
            }
            span {
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #C4C4C4;
                line-height: 12px;
            }
        }
    }
}

</style>

<style>
.el-carousel__button {
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    border-radius: 4px;
    opacity: 0.8;
}

.el-carousel__indicator.is-active button {
    width: 48px;
    height: 8px;
    background: #433FFF;
    border-radius: 4px;
    opacity: 1;
}

.login_box .el-input--prefix .el-input__inner {
    padding-left: 50px;
}

.login_box .el-input--suffix .el-input__inner {
    padding-right: 100px;
}
</style>
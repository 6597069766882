<template>
<div>
    <div class="footer__banner">
        <div class="show__1200__up hidden__1199__down" style="margin-bottom: -7px;">
            <el-link :underline="false" :href="largeBanner.link">
                <el-image :src="largeBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
        </div>
        <div class="hidden__1200__up show__1199__down" style="margin-bottom: -7px;">
            <el-link :underline="false" :href="smallBanner.link">
                <el-image :src="smallBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
        </div>
    </div>
    <div class="page__footer">
        <div class="main">
            <div class="footer__content flex__row align__center justify__between">
                <div class="footer__left flex__row align__center">
                    <div class="footer__logo">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/footer__logo.png'" style="width: 120px;height: 120px;"></el-image>
                    </div>
                    <div class="footer__info">
                        <div class="info__item" v-for="(item, index) in about" :key="index">
                            <span v-if="item.name != 'wxqrcode' && item.name != 'weixin'">{{ item.title || '' }}：{{ item.value || '' }}</span>
                        </div>
                    </div>
                </div>
                <div class="footer__links flex__row align__center">
                    <div class="link__item" v-for="(item, index) in links" :key="index">
                        <div class="links__module">
                            <div class="module__title">
                                <span>{{ item.name || '' }}</span>
                            </div>
                            <div class="module__list">
                                <div class="list__link" v-for="(link, ort) in item.child" :key="ort">
                                    <el-link :href="link.link" :underline="false">{{ link.name }}</el-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__content__mobile">
            <div class="footer__mobile__logo" style="margin-bottom: 40px;">
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/header__logo.png'" style="height: 75px;width: auto;"></el-image>
            </div>
            <div class="footer__mobile__company">
                <span>重庆魔女科技有限公司</span>
            </div>
            <div class="footer__mobile__info">
                <div class="" v-for="(item, index) in about" :key="index" :class="item.name === 'wxqrcode' ? 'wechat__code' : 'footer__mobile__info__item'">
                    <el-image 
                        class="footer__mobile__item__icon" 
                        :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/icon__footer__'+item.name+'.png'" 
                        v-if="item.name != 'working' && item.name != 'wxqrcode'"
                        :style="itemIcon(item.name)"></el-image>
                    <span v-if="item.name != 'working' && item.name != 'wxqrcode'">{{ item.title || '' }}：{{ item.value || '' }}</span>
                    <el-image v-if="item.name === 'wxqrcode'" :src="item.value" style="width: 190px;height: 190px;"></el-image>
                    <div v-if="item.name === 'wxqrcode'"  class="desc">
                        <span>扫一扫添加VX更快捷</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright__info flex__row align__center">
            <span>©2010-2023 重庆魔女科技有限公司 版权所有</span>
            <el-image class="icon__copyright" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/icon__copyright.png'" style="width: 18px;height: 20px;margin-left: 8px;margin-right: 8px;"></el-image>     
            <span>渝公网安备 50019002501024号</span>
        </div>
    </div>
</div>
</template>

<script>
import { getBanner, getBottom, getCompany } from '@/api/index';
export default {
    data() {
        return {
          www_copyright: 'xxxxx',
          links: [],
          largeBanner: {},
          smallBanner: {},
          about: []
        }
    },
    created() {
        this.getLargeBanner()
        this.getSmallBanner()
        this.getAbout()
        this.getBottomLinks()
    },
    methods: {
        goCopyright() {
          window.open('https://beian.miit.gov.cn/');
        },
        
        getLargeBanner() {
            getBanner({code: 'bottom'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        getSmallBanner() {
            getBanner({code: 'h5-bottom'})
            .then(response => {
                this.smallBanner = response.data[0]
            })
        },

        // 请求关于我们
        getAbout() {
            getCompany({id: 1})
            .then(response => {
                this.about = response.data
                // console.log(this.about)
            })
        },
        
        // 请求底部链接
        getBottomLinks() {
            getBottom({})
            .then(response => {
                this.links = response.data
            })
        },

        itemIcon(name) {
            let iconStyle = {
                width: '0px',
                height: '0px'
            }
            switch(name) {
                case 'phone':
                    iconStyle.width = '12px'
                    iconStyle.height = '13px'
                    break;
                case 'eamil':
                    iconStyle.width = '13px'
                    iconStyle.height = '10px'
                    break;
                case 'weixin':
                    iconStyle.width = '15px'
                    iconStyle.height = '12px'
                    break;
                case 'address':
                    iconStyle.width = '11px'
                    iconStyle.height = '13px'
            }
            return iconStyle
        },
    }
}
</script>

<style lang="scss" scoped>
.page__footer {
    background: #0D032A;
}

.main {
    margin: 0 auto;
}

.copyright__info {
    padding-top: 37px;
    padding-bottom: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    text-align: center;
    justify-content: center;
    span {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);
        line-height: 14px;
    }
}

@media screen and (max-width: 1199px) {
    .page__footer {
        background: #0B095B;
        text-align: center;
        padding: 78px 20px 36px 20px;
    }
    .flex__row {
        display: block;
    }

    .main {
        display: none;
    }

    .footer__mobile__company {
        margin-bottom: 40px;
        span {
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
        }
    }

    .footer__mobile__info {
        padding-bottom: 67px;
    }

    .footer__mobile__info__item {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            display: inline-block;
            margin-left: 10px;
            font-size: 13px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 27px;
        }
    }
    .footer__mobile__item__icon {
        width: auto;
        height: 13px;
    }
    .wechat__code {
        padding-top: 30px;
        span {
            font-size: 13px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #8C8AC4;
            line-height: 26px;
        }
    }

    .copyright__info {
        padding: 0;
        border: none;
        
        .icon__copyright {
            display: none;
        }

        span {
            display: block;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .footer__content__mobile {
        display: none;
    }
    .main {
        width: 100%;
        max-width: 1400px;
    }

    .footer__content {
        padding-top: 40px;
        padding-bottom: 65px;
    }

    .footer__logo {
        margin-right: 50px;
    }

    .footer__left {
        padding-right: 36px;
        border-right: 1px solid rgba(255, 255, 255, 0.15);
    }

    .footer__info {
        .info__item {
            span {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 36px;
            }
        }
    }

    .footer__links {
        .link__item {
            padding-left: 60px;
            padding-right: 50px;
            text-align: center;

            .module__title {
                margin-bottom: 10px;
                span {
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 40px;
                }
            }

            .list__link {
                span {
                    font-size: 14px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 29px;
                }
            }
        }
    }
}
</style>
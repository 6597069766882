<template>
    <div class="add-task">
        <el-card shadow="never" style="max-height: 800px;overflow: auto;">
            <el-form :model="form" ref="form" :rules="rules" label-width="130px">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Logo" prop="logo">
                            <el-image :src="form.logo" style="width: 50px;height: 50px;"></el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="应用名称" prop="name">
                            <el-input v-model="form.name" :disabled="banEdit" placeholder="请输入应用名称" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="下载链接" prop="app_store_id">
                            <el-input v-model="form.app_store_id" :disabled="banEdit" placeholder="请输入在AppStore中的下载链接" ></el-input>
                        </el-form-item>                    
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="试玩金额" prop="trial_amount">
                            <el-input-number size="large" :min="0.01" :precision="2" :step-strictly="false"  :step="1" v-model="form.trial_amount" placeholder="请输入试玩金额" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="试玩分钟" prop="trial_minute">
                            <el-input-number size="large" :min="1" :step="1" :step-strictly="true" v-model="form.trial_minute" placeholder="请输入试玩分钟数"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="跳转链接" prop="jump_url">
                            <el-input v-model="form.jump_url" :disabled="banEdit" placeholder="请输入跳转链接" ></el-input>
                        </el-form-item> 
                    </el-col>
                    <el-col :span="13"></el-col>
                    <el-col :span="12">
                        <el-form-item label="任务时间" prop="time_range">
                            <el-date-picker
                                :disabled="banEdit"
                                v-model="form.time_range"
                                type="datetimerange"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                start-placeholder="任务开始时间"
                                end-placeholder="任务结束时间"
                                :picker-options="pickerOptionsRange"
                                >
                            </el-date-picker>
                        </el-form-item>                   
                    </el-col>
                    <el-col :span="13"></el-col>
                    <el-col :span="12">
                        <el-form-item label="开启API对接" prop="api_abutment">
                            <el-switch
                                :disabled="banEdit"  
                                v-model="form.api_abutment"
                                active-text="是"
                                inactive-text="否"
                                :active-value="1"
                                :inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="IOS设备型号限制" prop="device_models" v-if="deviceList.length > 0">
                            <el-checkbox :disabled="banEdit"   :indeterminate="deviceIsIndeterminate" v-model="deviceCheckAll" @change="handleCheckAllDevice">全选</el-checkbox>
                            <el-checkbox-group v-model="form.device_models" @change="handleCheckedDevice">
                                <el-checkbox :disabled="banEdit"   v-for="(item, index) in deviceList" :key="index" :label="item">
                                {{ item }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="IOS设备系统限制" prop="device_systems" v-if="deviceList.length > 0">
                            <el-checkbox :disabled="banEdit"   :indeterminate="systemIsIndeterminate" v-model="systemCheckAll" @change="handleCheckAllSystem">全选</el-checkbox>
                            <el-checkbox-group v-model="form.device_systems" @change="handleCheckedSystem">
                                <el-checkbox :disabled="banEdit"   v-for="(item, index) in systemList" :key="index" :label="item">
                                {{ item }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>                   
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="关键词清单" prop="keywords_data">
                            <el-button type="text" style="color: #409EFF;" icon="el-icon-circle-plus" @click="handleAddKeyword" :disabled="banAddKeywords || banEdit">添加</el-button>
                            <div class="keyword__item" style="margin-bottom: 10px;" v-for="(item, index) in form.keywords_data" :key="index">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        关键词：
                                        <el-input v-model="item.keywords" :disabled="banEdit" placeholder="请输入关键词" @blur="(e) => keywordsGetRank(e, index)"></el-input>
                                    </el-col>
                                    <el-col :span="7">
                                        排名：
                                        <el-input v-model="item.ranking" disabled></el-input>
                                        <!-- <el-input-number :min="1" :step-strictly="true"  :step="1" v-model="item.ranking" placeholder="请输入排名" /> -->
                                    </el-col>
                                    <el-col :span="7">
                                        数量：
                                        <el-input-number :min="1" :step-strictly="true"  :disabled="banEdit"  :step="1" v-model="item.number" placeholder="请输入数量" />
                                    </el-col>
                                    <el-col :span="2">
                                        <el-button type="text" icon="el-icon-remove" @click="handleRemoveKeyword(index)" style="color: #F56C6C;" :disabled="banEdit">移除</el-button>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <div class="dialog-footer" style="margin-top: 20px;text-align: right;">
            <!-- <el-button type="primary" @click="submit">确 定</el-button> -->
            <el-button @click="close">关 闭</el-button>
        </div>
    </div>
</template>

<script>
import { getDevicesSelect, addTask, getDevicesRank, taskDetail } from '@/api/user'

export default {
    props: ['taskId'],
    data() {
        return {
            banEdit: true,
            form: {},
            userList: [],
            designatedUser: false,
            deviceList: [],
            deviceCheckAll: false,
            deviceIsIndeterminate: false,
            systemList: [],
            systemCheckAll: false,
            systemIsIndeterminate: false,
            banAddKeywords: false,
            pickerOptionsRange: {
                disabledDate(time) {
                    return time.getTime() < Date.now()
                }
            },
            rules: {
                name: [
                    { required: true, message: '应用名称不能为空', trigger: 'blur' },
                ],
                app_store_id: [
                    { required: true, message: '下载链接不能为空', trigger: 'blur' },
                ],
                jump_url: [
                    { required: true, message: '跳转链接不能为空', trigger: 'blur' },
                ],
                time_range: [
                    {required: true, message: '任务时间不能为空', trigger: 'change' }
                ],
                device_models: [
                    { required: true, message: '设备型号不能为空', trigger: 'change', type: 'array', min: 1}, 
                ],
                device_systems: [
                    { required: true, message: '设备系统不能为空', trigger: 'change', type: 'array', min: 1}, 
                ],
                keywords_data: [
                    { required: true, message: '关键词不能为空', trigger: 'change', type: 'array', min: 1}, 
                ],
            }
        }
    },
    created() {
        this.dataInit()
    },
    watch: {
        taskId: {
            handler(newVal, oldVal) {
                this.dataInit()
            },
            deep: true
        }
    },
    methods: {
        // 数据初始化
        dataInit() {
            this.reset()
            this.viewTask(this.taskId)
            this.getSelect(1)
            this.getSelect(2)
        },

        // 查询任务详情
        viewTask(id) {
            taskDetail({id})
                .then(response => {
                    let _data = Object.assign({}, response.data)
                    _data.time_range = [_data.start_time, _data.end_time]
                    _data.device_models = _data.device_model.split(',')
                    _data.device_systems = _data.device_system.split(',')
                    delete _data.start_time
                    delete _data.end_time
                    delete _data.device_model
                    delete _data.device_system
                   
                    this.form = _data
                })
        },

        // 请求设备或系统列表
        getSelect(type) {
            // type 1为设备型号，2为设备系统
            getDevicesSelect({type})
                .then(response => {
                    let _res = []
                    for (let i = 0; i < response.data.length; i ++) {
                        _res.push(response.data[i].label)
                    }
                    if (type === 1) {
                        this.deviceList = _res
                    } else if (type === 2) {
                        this.systemList = _res
                    }
                }) 
        },

         // 表单重置
         reset() {
            this.deviceCheckAll = false
            this.deviceIsIndeterminate = false
            this.systemCheckAll = false
            this.systemIsIndeterminate = false
            this.form = {
                logo: null, //
                name: null, // input
                app_store_id: null, // input
                api_abutment: 0, // switch
                time_range: [], // picker
                jump_url: null, // input
                device_models: [], // checkbox
                device_systems: [], // checkbox
                keywords_data: [], // 复合input
                trial_amount: null, // input-number
                trial_minute: null, // input-number
            }
            // this.resetForm('form')
        },

        // 设备型号全选
        handleCheckAllDevice(val) {
            this.form.device_models = val ? this.deviceList : []
            this.deviceIsIndeterminate = false
        },

        // 选择设备型号
        handleCheckedDevice(value) {
            const checkedCount = value.length
            this.deviceCheckAll = checkedCount === this.deviceList.length
            this.deviceIsIndeterminate = checkedCount > 0 && checkedCount < this.deviceList.length
        },

        // 设备系统全选
        handleCheckAllSystem(val) {
            this.form.device_systems = val ? this.systemList : []
            this.systemIsIndeterminate = false
        },

        // 选择设备系统
        handleCheckedSystem(value) {
            const checkedCount = value.length
            this.systemCheckAll = checkedCount === this.systemList.length
            this.systemIsIndeterminate = checkedCount > 0 && checkedCount < this.systemList.length
        },

        // 新增关键词
        handleAddKeyword() {
            let keyword = {
                keywords: '',
                number: 1,
                ranking: 1
            }
            this.form.keywords_data.push(keyword)
        },

        // 删除关键词
        handleRemoveKeyword(index) {
            this.form.keywords_data.splice(index, 1)
            this.form.keywords_data.forEach((item, i) => {
                this.$set(this.form.keywords_data, i, item)
            })
        },

        // 关闭弹窗
        close() {
            this.$emit('close', false)
        },

        // 添加任务并向父组件返回添加任务成功事件
        submit() {
            // console.log(this.form)
            this.$refs['form'].validate(valid => {
                if (valid) {
                    let _form = Object.assign({
                        device_model: this.form.device_models.toString(),
                        device_system: this.form.device_systems.toString(),
                        start_time: this.form.time_range[0],
                        end_time: this.form.time_range[1]
                    }, this.form)
                    delete _form.device_models
                    delete _form.device_systems
                    delete _form.time_range
                    _form.keywords_data = JSON.stringify(_form.keywords_data)
                    
                    addTask(_form)
                        .then(response => {
                            this.reset()
                            this.$message.success('创建任务成功')
                            this.close()
                             this.$emit('success', true)
                        })
                } 
            })
        },

        // 根据关键词获取排名
        keywordsGetRank(e, index) {
            const keywords = e.target.value
            if (keywords) {
                this.banAddKeywords = false
                getDevicesRank({keywords})
                    .then(response => {
                        // console.log(response)
                        if (response.data.ranking) {
                            this.form.keywords_data[index].ranking = response.data.ranking
                        }
                    })
            } else {
                this.banAddKeywords = true
               this.$message.error('关键词不能为空')
            }
            
        },
    }
}
</script>

<style lang="scss">
.add-task {
    .el-select {
        width: 100%;
    }

    .el-date-editor {
        width: 100%;
    }
}

.keyword__item .el-input {
    width: auto;
}
</style>
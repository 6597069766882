import { render, staticRenderFns } from "./FootBottom.vue?vue&type=template&id=6da86f7f&scoped=true"
import script from "./FootBottom.vue?vue&type=script&lang=js"
export * from "./FootBottom.vue?vue&type=script&lang=js"
import style0 from "./FootBottom.vue?vue&type=style&index=0&id=6da86f7f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da86f7f",
  null
  
)

export default component.exports